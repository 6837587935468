<template>
  <div>
    <div class="datapage-box">
      <div class="Title">
        <p>{{ title }}</p>
        <div class="Title-lower"></div>
      </div>
      <div class="datapage-box-info">
        <p v-html="pagehtml"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailData } from "@/network/api.js";
export default {
  data() {
    return {
      pagehtml: null,
      title: "",
    };
  },
  //activated
  created() {
    this.getlist();
  },
  watch: {
    "$route.query.id"(nv) {
      if (nv != undefined) {
        this.getlist();
      }
    },
  },
  methods: {
    // 根据id获得文章数据
    getlist() {
      getDetailData(this.$route.query.id).then((res) => {
        this.title = res.data.data.title;
        this.pagehtml = res.data.data.content;
      });
    },
  },
};
</script>

<style lang="scss">
.datapage-box {
  width: 10rem;
  margin: 0 auto;
  padding-top: 0.4rem;

  .datapage-box-info {
    margin-top: 0.3rem;

    .title {
      font-size: 0.18rem;
      color: #73d171;
      height: 0.5rem;
      line-height: 0.5rem;
    }

    p {
      font-size: 0.16rem;
      color: #fff;
      line-height: 1.75em;

      img {
        width: 100% !important;
        height: unset !important;
      }
    }
  }
}
</style>
